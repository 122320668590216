import React from "react";

import { rhythm } from "../utils/typography";
import Footer from "./Footer";
import Header from "./Header";

export default class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <Header isHomePage={location.pathname === rootPath} title={title} />
        <main>{children}</main>
        <Footer />
      </div>
    );
  }
}
