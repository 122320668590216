import React from "react";
import { rhythm } from '../utils/typography';

export default function Footer() {
  return (
    <footer
      style={{
        marginTop: rhythm(2.5),
        paddingTop: rhythm(1),
      }}
    >
      <a
        href="https://mobile.twitter.com/@roger_1591"
        target="_blank"
        rel="noopener noreferrer"
      >
        twitter
      </a>{" "}
      &bull;{" "}
      <a
        href="https://github.com/roger-rodriguez"
        target="_blank"
        rel="noopener noreferrer"
      >
        github
      </a>{" "}
      <div style={{ float: "right" }}>
        © {new Date().getFullYear()}, Roger Rodriguez
      </div>
    </footer>
  );
}
