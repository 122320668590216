import React from "react";
import { Link } from "gatsby";
import { rhythm, scale } from "../utils/typography";

export default function Header({ isHomePage, title }) {
  if (isHomePage) {
    return (
      <header>
        <h1
          className={'homepage__header'}
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      </header>
    );
  }

  return (
    <header>
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    </header>
  );
}
